import React, { useState } from "react";
import "./styles.css";
import { ArticlesList } from "./ArticlesList";
import { Categories } from "./Categories";
import useWindowSize from "../../hooks/useWindowSize";

const Blog = ({ filters, articles, authors }) => {
  const [byCategory, setByCategory] = useState(null)
  const windowSize = useWindowSize()

  return (
    <div className="Blog">
        <div className="[ Content md:Content_medium sm:Content_medium ]">
          <div className="Blog__content">
            <div className="Blog__header">
            <p className="Blog__title">{windowSize.width > 900 ? "Блог компании" : "Блог"}</p>
              <Categories categories={filters.categories} byCategory={byCategory} setByCategory={setByCategory} />
            </div>
            <ArticlesList articles={articles} authors={authors} byCategory={byCategory} />
          </div>
        </div>    
    </div>
  );
};

Blog.getLivePreviewData = ({ data, getAsset }) => ({
  filters: {
    ...data.filters,
  },
  articles: data.articles.map((article) => ({
    ...article,
    image: getAsset(article.image).url
  })),
  authors: data.authors.map((author) => ({
    ...author,
    avatar: getAsset(author.avatar).url
  }))
});

const blogQuery = graphql`
  fragment BlogFragment on MarkdownRemarkFrontmatter {
    filters {
      categories
    }
    authors {
      name
      position
      avatar {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    articles {
      title
      shortDescription
      createdOn
      views
      category
      slug
      image {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      author
    }
  }
`;

export { Blog, blogQuery };
