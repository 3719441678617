import React, { useEffect, useMemo, useReducer } from "react";
import { ArticleCard } from "../ArticleCard/ArticleCard";
import "./styles.css";


const ArticlesList = ({
    articles,
    authors,
    byCategory
}) => {
    const filteredArticles = useMemo(() => {
        return articles.filter(article => byCategory === null || article.category === byCategory)
    }, [articles, byCategory])

    return (
        <div className="ArticlesList">
            {filteredArticles.map((article, index) => (
                <ArticleCard 
                    category={article.category}
                    createdOn={article.createdOn}
                    shortDescription={article.shortDescription}
                    title={article.title}
                    views={article.views}
                    image={article.image}
                    author={authors.filter(author => author.id === article.author_id)[0]}
                    slug={article.slug}
                    key={index}
                />
            ))}
        </div>
    );
};

export { ArticlesList };
