import React from "react";

const ArrowMenu = ({stroke, strokeOpacity, strokeWidth, ...props}) => {
  return (
    <svg viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="none"
        d="M1 1L6 6L11 1"
        stroke={stroke ? stroke : "#194068"}
        strokeOpacity={strokeOpacity ? strokeOpacity : "0.48"}
        strokeWidth={strokeWidth ? strokeWidth : "2"}
      />
    </svg>
  );
};

export { ArrowMenu };
