import React, { useEffect } from "react";
import "./styles.css";
import { Image } from "../../Image";
import { Link } from "gatsby";


const ArticleCard = ({
      title,
      shortDescription,
      createdOn,
      views,
      image,
      category,
      author,
      slug
    }) => {

    const getViewsLabel = (views) => {
        const views_row = String(views);
        if (views_row[views_row.length - 1] === '1' || views_row.slice(-2) != "11") {
            return "просмотр";
        } else {
            return "просмотров";
        }
    }

    return (
        <div className="ArticleCard">
            <Link to={`/blog/${slug}`}>
                <div className="ArticleCard__image-container">
                    <Image className="ArticleCard__image" image={image} />
                </div>
                <p className="ArticleCard__title">{title}</p>
                <p className="ArticleCard__shortDescription">{shortDescription}</p>
                <div className="ArticleCard__under-section">
                    <p className="ArticleCard__category">#{category}</p>
                    <p className="ArticleCard__createdOn">{createdOn}</p>
                    <p className="ArticleCard__views">{views} {getViewsLabel(views)}</p>
                </div>
                <div className="ArticleCard__author">
                    <div className="ArticleCard__author-avatar-container">
                        <Image className="ArticleCard__author-avatar" image={author.avatar} />
                    </div>
                    <div className="ArticleCard__author-who">
                        <p className="ArticleCard__author-name">{author.name}</p>
                        <p className="ArticleCard__author-position">{author.position}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export { ArticleCard };
