import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.css";
import cn from "classnames";
import useWindowSize from "../../../hooks/useWindowSize";
import { ArrowMenu } from "../../../icons/ArrowMenu"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { DropDownMenu, DropDownMenuButton } from "../../DropDownMenu";

const Categories = ({
    categories,
    byCategory,
    setByCategory
}) => {
    const windowSize = useWindowSize()
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const menu = useRef(null);

    const toggleCategory = (category) => {
        if (byCategory === category) {
            setByCategory(null)
        } else {
            setByCategory(category)
        }
    }
    
    const handleToggleMenu = useCallback(() => setIsOpenMenu(!isOpenMenu), [
        isOpenMenu,
    ]);
    const handleCloseMenu = useCallback(() => setIsOpenMenu(false), []);

    useOnClickOutside(menu, handleCloseMenu);

    useEffect(() => {
        if (window) {
            window.addEventListener("scroll", handleCloseMenu, { passive: true });
        }
    });

    const handleCategoryClick = (category) => {
        handleCloseMenu()
        toggleCategory(category)
    }


    return (
        <div className="Categories">
            <div className={cn(
                "Categories__list",
                {
                    "hidden": windowSize.width <= 1305
                }
            )}>
                {categories.map(category => (
                    <div
                        className={cn(
                            "Categories__category",
                            { "active": byCategory === category }
                        )}
                        onClick={() => toggleCategory(category)}
                    >
                        #{category}
                    </div>
                ))}
            </div>
            <div className={cn(
                "Categories__dropdown",
                {
                    "hidden": windowSize.width > 1305
                }
            )}>
                <div className="Categories__selected-category" ref={menu}>
                    <div className={cn(
                        "Categories__category",
                        { "active": byCategory != null }
                    )}
                        onClick={handleToggleMenu}>
                        {byCategory ? "#" + byCategory : "Выбрать категорию"}
                        <div className="CatalogCatalog__category-arrow-container">
                            <ArrowMenu 
                                className="CatalogCatalog__category-arrow" 
                                stroke={byCategory ? "#FFFFFF" : "#2F80ED"}
                                strokeOpacity="1" 
                            />
                        </div>
                    </div>
                    <div className="Categories__dropdown-menu-container">
                        <DropDownMenu
                            className="Categories__menu"
                            isOpen={isOpenMenu}
                            onClose={handleCloseMenu}
                        >
                            {byCategory ? (
                                <DropDownMenuButton
                                    onClick={() => handleCategoryClick(null)}
                                >
                                    <div>Все категории</div>
                                </DropDownMenuButton>
                            ) : ""}
                            {categories.map(category => {
                                return (
                                    byCategory && byCategory === category ? 
                                    ("") : 
                                    (<DropDownMenuButton 
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        <div>#{category}</div>
                                    </DropDownMenuButton>)
                                )
                            })}
                        </DropDownMenu>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export { Categories };
