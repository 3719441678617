import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { Blog } from "../components/Blog";
import { Layout } from "../components/Layout";

const BlogPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <Blog {...props} />
    </>
  );
};

BlogPageTemplate.getLivePreviewData = (props) => ({
  ...props.data,
  ...Layout.getLivePreviewData(props),
  ...Blog.getLivePreviewData(props),
});

const BlogPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      {...data.layout.frontmatter}
      pageId={frontmatter.pageId}
      path={frontmatter.path}
    >
      <BlogPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query BlogPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        templateKey
        ...BlogFragment
      }
    }
  }
`;

export default BlogPage;

export { pageQuery, BlogPageTemplate };
